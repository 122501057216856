import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Button } from 'semantic-ui-react';

import { getFireDepartments } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REPORT_FDS_UI } from '../../js/actionTypes';
import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { PATH_ADMIN, PATH_REPORTS, PATH_FIRE_DEPARTMENT } from '../../js/paths';

import { formatDateTime } from '../../utils/date';
import { sort, downloadArrayBuffer, xlsxAutoWidth } from '../../utils/utils';

import SortTable from '../../components/SortTable';
import EditButton from '../../components/EditButton';
import ViewLoader from '../../components/ViewLoader';

import ListView from '../ListView';

import * as ExcelJS from 'exceljs/dist/exceljs';

class AdminReportFireDepartments extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: 'name',
        sortDescending: false,
      }
    };

    this.updateUIState = this.updateUIState.bind(this);
  }

  componentDidMount() {
    this.fetch();
    this.isClean();
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    await getFireDepartments(); //reload lookup just in case..
    this.props.fireDepartments.sort((a,b)=>a.name.localeCompare(b.name));
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.setState({ ui: { ...this.state.ui, ...state }}, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_FDS_UI });
    });
  }

  handleExportXLSX() {
    this._exportToXLSX();
  }

  render() {
    return (
      <div className='fire-department-report-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={6} floated='left'>
            <Header size='medium'>Report - Fire Departments and Instructors</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={8} floated='right'>
            <Button primary disabled={!this.props.fireDepartments || this.props.fireDepartments.length === 0} onClick={this.handleExportXLSX.bind(this)}>Export</Button>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              let fds = sort(this.props.fireDepartments, this.state.ui.sortField);

              console.log("FDs:", fds)

              if (fds && !fds.length) {
                return <Message info size='large'>
                  There are no Fire Departments.
                </Message>;
              }

              const center = { textAlign: 'center' };

              if (this.state.ui.sortDescending) { fds.reverse(); }

              return <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                { field: 'name',                  title: 'Fire Department',             width: '5' },
                { field: 'chief_name',            title: 'Fire Chief name',             width: '5' },
                { field: 'contact_name',          title: 'Contact Names',               width: '5' },
                { field: 'contact_email',         title: 'Contact Emails',              width: '5' },
                { field: 'acknowledgementdate',   title: 'Acknowledgement date',        width: '3', style: {...center} },
                { field: 'numtrainers',           title: 'Instructors in the program',  width: '4', style: {...center} },
                { field: 'maxallowedtrainers',    title: 'Instructors allowed',         width: '3', style: {...center} },
                { field: 'isInactive',            title: 'Is inactive',                 width: '3', style: {...center} },
                { field: 'buttons', noSort: true, title: '',                            width: '1' },
              ]}>
                { fds.map(({ id, gsi1pk, name, chief_name, contact_name, contact_email, acknowledgementdate, numtrainers, maxallowedtrainers, isInactive }, index) => (
                  <SortTable.Row key={ gsi1pk + "" + index }>
                    <SortTable.Cell>{ name }</SortTable.Cell>
                    <SortTable.Cell>{ chief_name }</SortTable.Cell>
                    <SortTable.Cell>{ contact_name }</SortTable.Cell>
                    <SortTable.Cell>{ contact_email }</SortTable.Cell>
                    <SortTable.Cell {...center}>{ (!acknowledgementdate ? "-" : formatDateTime(acknowledgementdate, DATE_FULL_MONTH_DAY_YEAR)) }</SortTable.Cell>
                    <SortTable.Cell {...center}>{ (!numtrainers ? "-" : numtrainers) }</SortTable.Cell>
                    <SortTable.Cell {...center}>{ (!maxallowedtrainers ? "-" : maxallowedtrainers) }</SortTable.Cell>
                    <SortTable.Cell {...center}>{ ((!isInactive || isInactive === false) ? "" : "Yes") }</SortTable.Cell>
                    <SortTable.Cell {...center}>
                      <EditButton view={ true } pathname={ `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_FIRE_DEPARTMENT }/${ id }` }/>
                    </SortTable.Cell>
                  </SortTable.Row>
                ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* Private XLSX */
  async _exportToXLSX() {
    const fds = sort(this.props.fireDepartments, this.state.ui.sortField);
    if (!fds || fds.length <= 0) return;
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow(['Fire Department', 'Fire Chief Name', 'Contact Names', 'Contact Emails', 'Acknowledgement date', 'Instructors in Program', 'Instructors Allowed', 'Is Inactive']);
    // Generate XLSX rows
    fds.forEach(({ name, chief_name, contact_name, contact_email, acknowledgementdate, numtrainers, maxallowedtrainers, isInactive }) => {
      ws.addRow([
        name, chief_name, contact_name, contact_email, (!acknowledgementdate ? "-" : formatDateTime(acknowledgementdate, DATE_FULL_MONTH_DAY_YEAR)), 
        (!numtrainers ? "-" : numtrainers), (!maxallowedtrainers ? "-" : maxallowedtrainers), ((!isInactive || isInactive === false) ? "\"" : "Yes")
      ]);
    });
    // Auto width
    xlsxAutoWidth(ws, 75);
    // Generate buffer and save it
    const buffer = await wb.xlsx.writeBuffer();
    downloadArrayBuffer(buffer, `fds-report`, 'xlsx');
  }
}

function mapStoreStateToProps(storeState) {
  return {
    fireDepartments: storeState.lookups.fireDepartments,
  };
}

export default connect(mapStoreStateToProps)(AdminReportFireDepartments);
